var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "textarea 컴포넌트", height: "700px" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    label: "기본",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: { editable: _vm.editable, label: "", name: "col1" },
                  model: {
                    value: _vm.data.col2,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col2", $$v)
                    },
                    expression: "data.col2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    label: "필수값",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "disable",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    counter: true,
                    maxlength: 25,
                    editable: _vm.editable,
                    label: "counter",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    prefix: "앞",
                    editable: _vm.editable,
                    label: "prefix 텍스트 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    suffix: "뒤",
                    editable: _vm.editable,
                    label: "prefix 텍스트 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    beforeIcon: _vm.icons,
                    editable: _vm.editable,
                    label: "before icon 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    prependIcon: _vm.icons,
                    editable: _vm.editable,
                    label: "prepend icon 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    appendIcon: _vm.icons,
                    editable: _vm.editable,
                    label: "append icon 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    afterIcon: _vm.icons,
                    editable: _vm.editable,
                    label: "afterIcon icon 넣기",
                    name: "col1",
                  },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    prependIcon: _vm.icons2,
                    editable: _vm.editable,
                    label: "icon 클릭",
                    name: "col1",
                  },
                  on: { iconclick: _vm.iconclick },
                  model: {
                    value: _vm.data.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col1", $$v)
                    },
                    expression: "data.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    type: "editor",
                    label: "Editor",
                    name: "col3",
                  },
                  model: {
                    value: _vm.data.col4,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "col4", $$v)
                    },
                    expression: "data.col4",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }