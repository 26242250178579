<template>
  <q-form ref="editForm">
    <c-card title="textarea 컴포넌트" height="700px" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :editable="editable"
            label="기본"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :editable="editable"
            label=""
            name="col1"
            v-model="data.col2">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :required="true"
            :editable="editable"
            label="필수값"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :disabled="true"
            :editable="editable"
            label="disable"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :counter="true"
            :maxlength="25"
            :editable="editable"
            label="counter"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            prefix="앞"
            :editable="editable"
            label="prefix 텍스트 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            suffix="뒤"
            :editable="editable"
            label="prefix 텍스트 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :beforeIcon="icons"
            :editable="editable"
            label="before icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :prependIcon="icons"
            :editable="editable"
            label="prepend icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :appendIcon="icons"
            :editable="editable"
            label="append icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :afterIcon="icons"
            :editable="editable"
            label="afterIcon icon 넣기"
            name="col1"
            v-model="data.col1">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :prependIcon="icons2"
            :editable="editable"
            label="icon 클릭"
            name="col1"
            v-model="data.col1"
            @iconclick="iconclick">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-textarea
            :editable="editable"
            type="editor"
            label="Editor"
            name="col3"
            v-model="data.col4">
          </c-textarea>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'textarea',
  data() {
    return {
      editable: true,
      data: {
        col1: '',
        col2: '라벨 없음',
        col3: '',
        col4: '',
        col5: '',
        col6: '',
      },
      icons: [
        { name: 'notifications_active', click: false, }
      ],
      icons2: [
        { name: 'notifications_active', click: true, callbackName: 'iconclick' }
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    iconclick() {
      window.alert('아이콘 클릭')
    }
  }
};
</script>
